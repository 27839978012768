import React, { Component } from 'react';
import TermsAndUse from '../images/terms_final.pdf';

export default class TermsOfUse extends Component {
  render() {
    return (
      <>
        <iframe style={{ width: '100%', height: '100%' }} src={TermsAndUse} />
      </>
    );
  }
}
